@import "../../styles/globals.scss";

.main {
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  &.frame {
    padding: 15px;
    background-color: $white;

    @include sm {
      padding: 40px;
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: -8px;
    color: $black;
    font-family: $font;
    font-size: 15px;
    line-height: 1.1;

    &:first-of-type {
      font-weight: bold;
    }
  }

  @include sm {
    margin-bottom: 60px;

    p {
      margin-top: 25px;
      margin-bottom: -15px;
      font-size: 20px;
    }
  }

  p,
  strong,
  em::selection {
    background-color: $black;
    color: $white;
  }
}

.video {
  width: 100%;
  padding: 56.25% 0 0 0;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
