@mixin xxs {
    @media (min-width: 321px) {
      @content;
    }
  }
  
  @mixin xs {
    @media (min-width: 500px) {
      @content;
    }
  }
  
  @mixin sm {
    @media (min-width: 769px) {
      @content;
    }
  }
  
  @mixin md {
    @media (min-width: 1200px) {
      @content;
    }
  }
  
  @mixin lg {
    @media (min-width: 1500px) {
      @content;
    }
  }
  
  @mixin xl {
    @media (min-width: 1920px) {
      @content;
    }
  }
  