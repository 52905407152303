@font-face {
    font-family: 'Univers LT Std';
    src: url('./fonts/UniversLTStd-Bold.woff2') format('woff2'),
        url('./fonts/UniversLTStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('./fonts/UniversLTStd-Light.woff2') format('woff2'),
        url('./fonts/UniversLTStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('./fonts/UniversLTStd-LightObl.woff2') format('woff2'),
        url('./fonts/UniversLTStd-LightObl.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

$font: 'Univers LT Std', sans-serif;