@import "../../styles/globals.scss";

.main {
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 44px;
  padding-bottom: 44px;
  padding-left: 15px;
  padding-right: 15px;

  @include sm {
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 40px;
    padding-right: 40px;
  }

  canvas {
    width: 100%;
    height: 100%;
    touch-action: none;
  }
}
