@import "../../styles/globals.scss";

.main {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;

  margin-bottom: 40px;

  @include sm {
    margin-bottom: 60px;
  }

  &.frame {
    padding: 15px;
    background-color: $white;

    @include sm {
      padding: 40px;
    }
  }

  &.square {
    @include sm {
      width: 45%;
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: -8px;
    color: $black;
    font-family: $font;
    font-size: 15px;
    line-height: 1.1;
  
    &:first-of-type {
      font-weight: bold;
    }
  }
  
  @include sm {
    p {
      margin-top: 25px;
      margin-bottom: -15px;
      font-size: 20px;
    }
  }
  
  p,
  strong,
  em::selection {
    background-color: $black;
    color: $white;
  }
}





.mainImage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.hoverImage {
  width: 100%;
  position: absolute;
  z-index: 7;
  top: 0;
  left: 0;

  &.frame {
    width: calc(100% - 30px);
    top: 15px;
    left: 15px;

    @include sm {
      width: calc(100% - 80px);
      top: 40px;
      left: 40px;
    }
  }
}
