@import "../../styles/globals.scss";

.main {
    position: fixed;
    overflow-y: scroll;
    background-color: $black;
    z-index: 9;
  
    height: calc(100% - 88px);
    top: 44px;
    right: 15px;
    width: calc(100% - 30px);
    padding-right: 10px;
   
  
    @include sm {
      height: calc(100% - 150px);
      width: 40%;
      top: 75px;
      right: 40px;
    }
  
    &::-webkit-scrollbar {
        width: 15px;
    }
  
    &::-webkit-scrollbar-track {
      color: $black;
      background-color: $black;
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: $white;
      color: $white;
     
    }
  }

  .project {

    padding-bottom: 15px;
    padding-left: 15px;
    text-decoration: none;
    display: block;

    p {
        color: $white;
        font-family: $font;
        font-size: 15px;
        line-height: 1.1;
        
        &:nth-of-type(2) {
            padding-left: 20px;
        }
    }

    @include sm {
        
        padding-bottom: 20px;
        padding-left: 40px;

        p {
            font-size: 20px;

            &:nth-of-type(2) {
                padding-left: 40px;
            }
        }
    }

    &:hover {
        cursor: pointer;
        
        p {
            color: $highlight;
        }
    }

    &::selection {
        background-color: $white;
        color: $black;
    }
  }