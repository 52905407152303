@import "../../styles/globals.scss";

.main {
    width: 100%;
    height: auto;
    padding: 15px;
    background-color: $white;
    margin-bottom: 40px;

    p {
        color: $black;
        font-family: $font;
        font-weight: 300;
        font-size: 15px;
        line-height: 1.1;
        padding-bottom: 40px;

        &:last-of-type {
            padding-bottom: 0px;
        }
        
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    @include sm {
      padding: 40px;
      margin-bottom: 60px;

      p {
        
        font-size: 20px;
        }
    }

    p::selection {
        background-color: $black;
        color: $white;
    }

    em::selection {
        background-color: $black;
        color: $white;
    }

    strong::selection {
        background-color: $black;
        color: $white;
    }

    
}