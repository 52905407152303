@import "../../styles/globals.scss";

.top {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $black;

  height: 44px;

  @include sm {
    height: 75px;
  }
}

.bottom {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $black;
  

  height: 44px;

  @include sm {
    height: 75px;
  }
}

.left {
  position: fixed;
  z-index: 10;
  left: 0;
  width: 15px;
  height: 100vh;
  background-color: $black;
  top: 0;

  @include sm {
    width: 40px;
  }
}

.right {
  position: fixed;
  z-index: 10;
  right: 0;
  width: 15px;
  height: 100vh;
  background-color: $black;
  top: 0;

  @include sm {
    width: 40px;
  }
}
