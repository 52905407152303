@import "../../styles/globals.scss";

.top {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;

  height: 44px;

  @include sm {
    height: 75px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.bottom {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;


  height: 44px;

  @include sm {
    height: 75px;
    top: calc(100vh - 75px);
    padding-left: 40px;
    padding-right: 40px;
  }
}

.button {
  color: $white;
  font-family: $font;
  font-size: 13px;
  text-decoration: none;
  pointer-events: all;
    font-weight: bold;
    width: 25%;

    
    

  @include sm {
    font-size: 20px;
  }

  &:hover {
    cursor: pointer;
    color: $highlight;
  }

  &::selection {
    background-color: $white;
    color: $black;
    }   
}
