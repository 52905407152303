@import "../../styles/globals.scss";

.main {
    position: relative;
    
    z-index: 15;
    padding-top: 88px;
    padding-bottom: 120vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    
    // pointer-events: none;

    width: calc(100% - 60px);
    margin-left: 30px;

    @include xs {
        width: calc(100% - 100px);
        margin-left: 50px;
    }

    @include sm {
        padding-top: 150px;
        width: calc(100% - 200px);
        margin-left: 100px;
    }

    @include md {
        width: calc(100% - 300px);
        margin-left: 150px;
    }

    @include lg {
        width: calc(100% - 400px);
        margin-left: 200px;
    }

    @include xl {
        width: calc(100% - 600px);
        margin-left: 300px;
    }
}