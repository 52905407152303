@import "../../styles/globals.scss";

.main {
  position: fixed;
  overflow-y: scroll;
  background-color: $black;
  z-index: 9;

  height: calc(100% - 88px);
  top: 44px;
  left: 15px;
  width: calc(100% - 30px);
  padding-right: 20px;
  p, strong, em {
    color: $white;
    font-family: $font;
    font-weight: 300;
    font-size: 15px;
    line-height: 1.1;
    padding-bottom: 15px;

    &::selection {
        background-color: $white;
        color: $black;
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  @include sm {
    height: calc(100% - 150px);
    width: 40%;
    top: 75px;
    left: 40px;

    p, strong, em {
      font-size: 20px;
      padding-bottom: 20px;
    }
  }

  

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    color: $black;
    background-color: $black;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $white;
    color: $white;
  }
}
