@import "../../styles/globals.scss";

.main {
    position: fixed;
    
    width: calc(100% - 30px);
    height: calc(100vh - 88px);
    left: 15px;
    top: 44px;
    z-index: 8;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
  

    @include sm {
        width: calc(100% - 80px);
        height: calc(100vh - 150px);
        left: 40px;
        top: 75px;
    
 
    }
}